@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "TrustlySans";
  src: url("fonts/TrustlySans-Regular.woff2");
}
@font-face {
  font-family: "TrustlySans";
  src: url("fonts/TrustlySans-Bold.woff2");
  font-weight: 600;
}
@font-face {
  font-family: "TrustlySans";
  src: url("fonts/TrustlySans-Medium.woff2");
  font-weight: 500;
}
@font-face {
  font-family: "TrustlySans";
  src: url("fonts/TrustlySans-BoldHeadline.woff2");
  font-weight: 700;
}

html {
  max-height: 100vh;
  height: 100%;
}

body, #root {
  height: 100%;
}